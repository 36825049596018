<template>
  <div class="reservation-modal">
    <default-reservation-modal v-on="$listeners" v-bind="$props" showVehicleClass :showNotes="type==='show'"
                               :showMetadata="type==='show'" :showEditVin="type!=='show'"
                               :showEditLicensePlate="type!=='show'" :showEditNotes="type!=='show'"
                               :showEditMetadata="type!=='show'"/>
  </div>
</template>

<script>
import DefaultReservationModal from "@/components/default_reservation_modal";

export default {
  name: "ReservationModal",
  components: {DefaultReservationModal},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "show"
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>