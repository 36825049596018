<template>
  <div class="transfers-created-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             no-close-on-backdrop no-close-on-esc
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <div class="text-center">
          <h1>{{ $t('transfers.transfers_created') }}</h1>
          <h4>{{ $t('transfers.add_more_information_question') }}</h4>
        </div>
      </template>

      <template #default="{ ok }">
        <b-row>
          <b-col>
            <h5>{{ $t('transfers.transfers_created_count') }}</h5>
            {{ records.length }}
          </b-col>
          <b-col>
            <h5>{{ $t('transfers.transfers_created_price') }}</h5>
            {{ records.reduce((r, o) => r + o.price, 0) | formatPrice }}€
          </b-col>
        </b-row>
        <b-form ref="form" @submit.prevent="ok(form)">
          <ul class="list-group m-t-md">
            <li class="list-group-item">
              <generic-input type="text" :label="$t('common.order_number')" v-model="form.external_order_number"/>
            </li>
          </ul>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm" variant="default" @click="cancel">{{ $t('button.back') }}</b-button>
          <b-button size="sm" variant="primary" type="submit" @click.prevent="$refs.form.requestSubmit()">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {putTransfer} from "@/services/transfers";
import GenericInput from "@/components/generic_input";
import {EventBus} from "@/eventbus";

export default {
  name: "TransfersCreatedModal",
  components: {GenericInput},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    records: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      form: {
        external_order_number: ''
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          this.form.external_order_number = ''
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {

  },
  methods: {
    async submitModal() {
      if (this.form.external_order_number.trim().length > 0) {
        EventBus.$emit("toggleProgressSpinner", true)
        try {
          for (let i = 0; i < this.records.length; i++) {
            const record = this.records[i]
            await putTransfer(record.id, this.form, { toggleProgressSpinner: false, toast: { skip: i < this.records.length - 1 }})
          }
          EventBus.$emit("toggleProgressSpinner", false)
        } catch {
          EventBus.$emit("toggleProgressSpinner", false)
          return
        }
      }
      this.visible = false
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
